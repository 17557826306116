import {
    $, addAction, addFilter, INIT, LAYOUT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $homeHeroLogo = $('.home-hero .icon-logo');
    if (!$homeHeroLogo.length) {
        return;
    }
    addFilter('css-vars/register', (styles) => $.extend({}, styles, { 'home-hero-logo-height': () => `${Math.round($homeHeroLogo.outerHeight() * 0.8)}px` }));
    const $criticsPick = $('.home-hero__critics-pick');
    if ($criticsPick.length) {
        addAction(LAYOUT, () => {
            $criticsPick.removeClass('hide');
            $criticsPick.css($homeHeroLogo.position());
            const pos = $criticsPick.position();
            $criticsPick.toggleClass('hide', (pos.top < 30 || pos.left < -25));
        }, 5);
    }
});
