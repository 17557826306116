import {
    $, addAction, INIT, applyFilters,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    $('a.scroll-to, .scroll-to a').on('click', (e) => {
        const $target = $(e.currentTarget.getAttribute('href'));
        if ($target.length) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $target.offset().top - applyFilters('header-height'),
            }, 500);
        }
    });
});
